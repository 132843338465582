<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/wisdom/inspectionRoutePointRel" :gridOptions="gridOptions"  :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ["currNode", "selectRow"],
  data() {
    return {
      service: '',
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "selectPoint",
              buttonRender: {
                name: '$ToolbarSelectInspectionPoint',
                props: {
                  content: "选择路线点",
                  status: "primary",
                },
                params: {
                  communityId: this.selectRow.communityId,
                  parentId: this.selectRow.id
                },
                events: {
                  click: (params, row) => {
                    this.submitEvent(row);
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'inspectionName',
            title: '巡检点名称',
            minWidth: 160,
            editRender: {
              name: 'input'
            }
          },
          {
            field: "inspectionPlace",
            title: "巡检点位置",
            minWidth: 120,
            editRender: {
              name: "$input",
            },
          },
        ],
        exportConfig: {},
        // pagerConfig: {
        //  enabled: false
        // },
        importConfig: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionName",
            title: "巡检点名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检点名称",
              },
            },
          },
         
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          parentId: this.selectRow.id,
          communityId: this.selectRow.communityId,
          inspectionRouteId: this.selectRow.id,
          inspectionName: ''
        },
      },
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    ...mapActions(["submitEventService"]),
    submitEvent(row) {
      let params = {
        parentId: this.selectRow.communityId,
        communityId: this.selectRow.communityId,
        inspectionRouteId: this.selectRow.id,
        points: [row],
      }
      console.log(params)
      this.submitEventService({
        service: "/wisdom/inspectionRoutePointRel",
        params: params,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
