<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="选择路线点" width="800" height="500" show-zoom>
    <template v-slot>
      <RoutePoint :currNode="currNode" :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import RoutePoint from './RoutePoint'
export default {
  components: {
    RoutePoint,
  },
  data() {
    return {
      selectRow: null,
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "routeName",
            title: "路线名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入路线名称"
              }
            }
          },
          {
            field: "seq",
            title: "路线顺序",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入路线顺序"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          {
            field: "createTime",
            title: "创建时间",
            width: 180

          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "selectRoutePoint",
                  status: "primary",
                  label: "选择检修点",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   }
          // },
          // {
          //   fixed: "right",
          //   title: "操作",
          //   width: 160,
          //   editRender: {
          //     name: "$VxeGridButtons"
          //   }
          // }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "routeName",
            title: "路线名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入路线名称"
              }
            }
          },
          {
            field: "seq",
            title: "路线顺序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                placeholder: "请输入路线顺序",
              },
            },
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          routeName: [{ required: true, message: "请输入路线名称" }],
          seq: [{ required: true, message: "请输入路线顺序" }]
          // enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          clientType: "1",
          content: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "routeName",
            title: "路线名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入路线名称"
              }
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          routeName: "",
          enabled: ""
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.formOptions.data['communityId'] = node.id;
      this.formOptions.data['enabled'] = '1';

      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
